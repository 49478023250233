<template>
  <vk-modal center :show="show" size="container">
    <vk-modal-close @click="close()"></vk-modal-close>
    <vk-modal-title slot="header" v-if="show">
      {{ currentView.name }}
    </vk-modal-title>
    <video 
      v-if="show" 
      :src="baseUrl + currentView.url" 
      width="1200" 
      height="600" 
      playsinline 
      controls
      uk-video="autoplay: inview"
      class="video-formation"
    ></video>
  </vk-modal>
</template>

<script>
  export default {
    name: 'VideoFormationModal',
    props: {
      currentView: {
        required: true,
        defaultValue: null,
      }
    },
    computed: {
      show(){
        return this.currentView != null
      },
      baseUrl(){
        return this.$env.VUE_APP_MODEL_API_ROOT + '/'
      }
    },
    methods: {
      close(){
        this.$emit('close')
      }
    }
  }
</script>

<style lang="scss">
  .uk-modal-header {
    border-bottom: 1px solid #e5e5e5 !important;
    text-align: center;
  }

  .video-formation{
    max-height: 600px;
  }
</style>